.progress-bar-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 300px;
	background-color: #f0f0f0;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	padding: 10px;
	z-index: 1000;
}

.progress-bar {
	height: 20px;
	background-color: #4CAF50;
	border-radius: 5px;
	text-align: center;
	line-height: 20px;
	color: white;
}

.progress-text {
	text-align: center;
	margin-top: 5px;
}