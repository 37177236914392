.photo-viewer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.transform-wrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.transform-content {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#photoImage {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	z-index: 10000;
}

.zoomable-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	pointer-events: none;
	touch-action: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	pointer-events: none;
}

.thumbnail-gallery-overlay {
	position: absolute;
	bottom: 60px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
}

.thumbnail-gallery {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.5);
	white-space: nowrap;
	scrollbar-width: none;
	-ms-overflow-style: none;
	border-radius: 10px;
	margin-bottom: 50px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

}

.thumbnail-gallery::-webkit-scrollbar {
	display: none;
}

.thumbnail {
	width: 40px;
	height: 40px;
	object-fit: cover;
	margin-right: 10px;
	cursor: pointer;
	border: 2px solid transparent;
	transition: border-color 0.3s ease;
	border-radius: 5px;

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

.thumbnail.active {
	border-color: #f00;
}


.controls {
	position: absolute;
	bottom: 60px;
	height: 30px;
	width: 400px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1001;
	display: flex;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 12px;
	padding: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.controls button {
	margin: 0 5px;
	font-size: 11px;
	transition: background-color 0.3s ease;
	margin-left: 10px;
	padding: 5px 10px;
	color: white;
	background-color: rgba(128, 128, 128, 0.7);
	border: none;
	border-radius: 8px;
	cursor: pointer;
}

.controls button:hover {
	filter: brightness(120%);
}

.controls button:active {
	filter: brightness(80%);
}

.photo-info {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	font-size: 14px;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 10px 25px 10px 25px;
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out;
	z-index: 1001;
}

.photo-info.visible {
	transform: translateY(0);
}

.info-content {
	display: flex;
	justify-content: space-between;
	align-items: left;
}

.toggle-info {
	position: absolute;
	top: -40px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
}

.delete-button,
.close-button-photo,
.move-button {
	background-color: rgba(220, 0, 0, 0.7);
	color: white;
	border: none;
	border-radius: 8px;
	padding: 7px 16px;
	z-index: 2000;
	cursor: pointer;
}

.delete-button {
	position: absolute;
	left: 10px;
	top: 25px;
}

.close-button-photo {
	position: absolute;
	top: 25px;
	right: 10px;
}

.move-button {
	position: fixed;
	top: 25px;
	left: 50%;
	transform: translate(-50%, 0%);
}

#photoImage {
	z-index: 10000;
}

@media (max-width: 768px) {

	.close-button-photo,
	.delete-button,
	.move-button {
		position: absolute;
		top: 65px;
	}

	.photo-info {
		font-size: 9px;
	}

	.info-content {
		white-space: wrap;
		overflow: hidden;
	}

	.info-content p {
		width: 50%;
		margin: 2px 0;
	}
}

.memo-input-container {
	position: absolute;
	bottom: 180px;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 5px 10px;
	border-radius: 10px;
	z-index: 1002;
	transition: all 0.3s ease;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
}

.memo-input-container input {
	background-color: rgba(255, 255, 255, 0.9);
	color: black;
	border: none;
	padding: 5px;
	width: 300px;
	border-radius: 3px;
}

.memo-input-container input:focus {
	outline: none;
	box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}