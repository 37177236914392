.chat-panel {
    position: fixed;
    right: -300px;
    top: 0;
    width: 300px;
    height: 100%;
    padding-right: 0px;
    background-color: #40596a;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    overflow-y: auto;
    opacity: 0.9;
    z-index: 15;
    text-align: left;
    color: white;
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
}

.chat-panel.open {
    right: 0;
}

.chat-panel h2 {
    padding: 12px;
    padding-left: 90px;
    margin: 0;
    background-color: #30495a;
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
}

.message {
    margin-bottom: 10px;
    background-color: #4a6a7a;
    padding: 5px;
    border-radius: 5px;
}

.message p {
    margin: 5px 0 0 0;
}

.chat-input {
    display: flex;
    padding: 10px;
}

.chat-input input {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #567;
    border-radius: 8px;
}

.chat-input button {
    margin-left: 5px;
    padding: 5px 10px;
    background-color: #f93;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.chat_buttonN {
    position: absolute;
    top: 10px;
    left: 10px;
    width:70px;
    margin: 1px 1px;
    padding: 2px 7px;
    font-size: 12px;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color:#f93;
}

.message {
    margin-bottom: 10px;
    background-color: #4a6a7a;
    padding: 10px;
    border-radius: 5px;
}

.message-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.userIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.message-info {
    display: table-column;
    flex-direction: column;
}

.message-info strong {
    font-size: 0.9em;
}

.timestamp {
    margin-left: 10px;
    font-size: 0.7em;
    color: #ccc;
}

.message-content {
    margin: 0;
    padding-left: 40px;
}