/* popup.css */

.popup,.popup_opaque {
	position: fixed;
	top: 50%;
	left: 50%;
	font-size: 1.0em;
	transform: translate(-50%, -50%);
	padding: 15px;
	color: white;
	background-color: #60798a;
	border: 2px solid #000;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	opacity: 1.0;
	z-index: 1000;
	border-radius: 10px;
}

.popup_opaque {
	background-color: #516979;
}

.popup-inner {
	padding: 5px 20px;
	text-align: center;
}

.popup-inner .left-aligned-text {
	text-align: left;
}

.popup-inner h2 {
	margin-top: 0;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	pointer-events: none;
}

.selectedIcon {
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color: #fff;
	border-radius: 5px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

.popup-inner label {
	display: block;
	margin-bottom: 5px;
	text-align: left;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

.popup-inner-label-nowrap {
	padding: 8px 20px;
	display: flex;
	margin-bottom: 10px;
	text-align: left;
}

.popup-inner input,
.popup-inner textarea,
.popup-inner select { /* 追加 */
	width: calc(100%);
	padding: 5px;
	margin-top: 5px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}

.popup-inner button {
	margin: 5px 5px;
	padding: 10px 20px;
	border: none;
	opacity: 1.0;
	border-radius: 12px;
	cursor: pointer;
}

.popup-inner img {
	display: block;
	text-align: left;
}

#button_thumbnail {
	background-color: #39c;
	color: white;
	margin-bottom: 15px;
}

.button_y:hover,
.button_b:hover,
.button_r:hover,
#button_thumbnail:hover {
	filter: brightness(120%);
	transform: translateY(-2px);
}


.button_b {
	background-color: #39c;
	color: white;
}

.button_y {
	background-color: #f93;
	color: white;
}

.button_r {
	color: white;
	margin-bottom: 15px;
	background-color: #f30;
}

.scrollable-list {
	max-height: 200px;
	overflow-y: auto;
	margin-bottom: 20px;
}

.scrollable-list ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.scrollable-list li {
	height: 40px;
	line-height: 30px;
	border-bottom: 1px solid #ccc;
}

.rowBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
	text-align: center;
}

.close_button {
	position: absolute;
	top: 0px;
	right: 5px;
	font-size: 24px;
	cursor: pointer;
}

.color-dropdown {
	position: relative;
	display: inline-block;
}

.color-dropdown-button {
	background-color: white;
	border: 1px solid #ccc;
	padding: 5px;
	font-size: 16px;
	cursor: pointer;
}

.color-dropdown-content {
	display: none;
	position: absolute;
	color:black;
	background-color: white;
	min-width: 160px;
	max-height: 200px;
	/* 高さを制限 */
	overflow-y: auto;
	/* スクロールバーを表示 */
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.color-dropdown:hover .color-dropdown-content {
	display: block;
}


.popup-inner input[type="text"],
.popup-inner select {
	width: 100%;
	margin-top: 5px;
}

.popup-inner {
	padding: 5px 20px;
	text-align: center;
}

.popup-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: move;
	/* ドラッグ可能であることを示すカーソル */
}

.popup-header h2 {
	margin: 0;
	white-space: nowrap;
	user-select: none;
	pointer-events: none;
}

.close_button {
	font-size: 24px;
	cursor: pointer;
}

.popup,
.popup_opaque {
	position: fixed;
	top: 50%;
	left: 50%;

	font-size: 1.0em;
	padding: 15px;
	color: white;
	background-color: #60798a;
	border: 2px solid #000;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	opacity: 1.0;
	z-index: 1000;
	border-radius: 10px;
}

.palette_popup {
	position: fixed;
	top: calc(50% - var(--popup-height, 0px) / 2);
	left: calc(50% - var(--popup-width, 0px) / 2);
	font-size: 1.0em;
	padding: 15px;
	color: white;
	background-color: #60798a;
	border: 2px solid #000;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	opacity: 1.0;
	z-index: 1000;
	border-radius: 10px;
}

.text_link {
	color: red;
	font-weight: bold;
	margin-top: 30px;
	display: inline-block;
	text-decoration: none;
	transition: transform 0.3s ease;
	text-shadow:
		-1px -1px 0 white,
		1px -1px 0 white,
		-1px 1px 0 white,
		1px 1px 0 white;
}

.text_link:hover {
	transform: translateY(-3px);
}

.popup-inner .checkbox-wrapper {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 15px;
}

.popup-inner .checkbox-container {
	display: flex;
	align-items: center;
	/* 追加: 垂直方向の中央揃え */
	gap: 8px;
	/* 追加: 要素間の間隔を設定 */
}

.popup-inner .checkbox-container input[type="checkbox"] {
	margin: 0;
	/* リセット */
	margin-left: 25px;
	width: 20px;
	height: 20px;
	/* 追加: 高さを明示的に設定 */
}

.popup-inner .checkbox-container label {
	margin: 0;
	/* リセット */
	white-space: nowrap;
	display: flex;
	/* 追加 */
	align-items: center;
	/* 追加 */
}

.handedness-control {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.handedness-control label {
	margin-right: 10px;
	display: flex;
	align-items: center;
}

.handedness-control input[type="radio"] {
	margin-right: 5px;
}

.popup-inner h3 {
	margin-top: 20px;
	margin-bottom: 10px;
}

.popup-inner select {
	width: 100%;
	padding: 5px;
	margin-top: 5px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}

.button-container {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.button {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;;
}

/* Style for color dropdown */
.popup-inner select[style] {
	color: white;
	text-shadow: 0 0 2px black;
}

.popup-inner select[style] option {
	color: initial;
	text-shadow: none;
}

.user-menu {
	position: fixed;
	top: 50px;
	/* アイコンの下に表示されるように調整 */
	right: 10px;
	background-color: #60798a;
	border: 2px solid #000;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	padding: 10px;
	z-index: 1000;
	color: white;
	font-size: 1em;
	min-width: 150px;
	max-width: 250px;
	/* 最大幅を設定 */
}

.user-menu-item {
	padding: 8px 12px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border-radius: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	/* 下線を追加 */
}

.user-menu-item:last-child {
	border-bottom: none;
	/* 最後の項目の下線を削除 */
}

.user-menu-item:hover {
	background-color: #516979;
}

.user-menu-item:active {
	background-color: #39c;
}

@media (max-width: 480px) {
	.user-menu {
		width: calc(100% - 20px);
		/* 画面幅に合わせて調整 */
		max-width: none;
		right: 10px;
		left: 10px;
	}
}