.layer-list-panel {
	position: fixed;
	left: -320px;
	top: 0;
	width: 270px;
	height: 100%;
	padding-left: 50px;
	background-color: #40596a;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
	transition: left 0.3s ease-in-out;
	overflow-y: auto;
	opacity: 0.8;
	z-index: 90;
	text-align: left;
	font-size: 1.2em;
	color: white;
	display: flex;
	flex-direction: column;
}

.layer-list-panel.open {
	left: 0;
	
}

.layer-list-panel h2 {
	padding: 12px;
	margin: 0;
	background-color: #30495a;
}

.layer-list-panel ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.layer-list-panel li {
	padding: 10px;
	border-bottom: 1px solid #567;
	word-wrap: break-word;
	word-break: break-all;
	white-space: normal;
}

.buttonN {
	position: absolute;
	top: 10px;
	right: 10px;
	margin: 1px 1px;
	padding: 2px 7px;
	font-size: 12px;
	color: white;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	background-color:#f93;
}

.dl_button,.delete_button {
	margin: 5px 5px;
	padding: 4px 12px;
	font-size: 16px;
	color: white;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	background-color: #39c;
	margin-left: 10px;
	white-space: nowrap;
}

.delete_button {
	background-color: #f30;
	font-size: 14px;
}

.delete_button:hover,
.header button:hover,
.buttonN:hover,
.dl_button:hover {
	filter: brightness(120%);
	transform: translateY(-2px);
}


.layer-list-panel .header {
	padding: 10px;
	display: flex;
	justify-content: space-around;
}

.layer-list-panel .header button {
	padding: 5px 10px;
	font-size: 12px;
	color: white;
	background-color: #f93;
	border: none;
	border-radius: 8px;
	cursor: pointer;
}

.layer-list-panel .header button:hover {
	filter: brightness(120%);
	transform: translateY(-2px);
}

.layer-list-panel ul li>div {
	padding: 5px 0;
}

.file-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.listItem {
	cursor: pointer;

}

.photo-item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.photo-thumbnail {
	width: 36px;
	height: 36px;
	object-fit: cover;
	margin-right: 10px;
	cursor: pointer;
}

.photo-item span {
	flex-grow: 1;
}

.photo-item .delete_button {
	margin-left: 10px;
}