.App {
	text-align: center;
	background-color: #30495a;
	color: white;
	display: flex;
	height: 100vh;
	flex-direction: column;
	overflow: hidden;
}

#App_Title {
	position: absolute;
	height: 30px;
	font-size: 1.2em;
	top: 5px;
	left: 50%;
	transform: translateX(-50%);
}

#Recently_seen_list {
	padding-bottom: 100px;
}

.header_container {
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	padding: 0;
	box-sizing: border-box;
	z-index: 10;
	background-color: #30495a;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 0;
}

.header_left {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.header_left>.header_cell {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	-webkit-user-drag: none;
	user-select: none;
	cursor: pointer;
}

.header_left>.header_cell:last-child {
	border-bottom: none;
}

.header_right {
	width: 50px;
	display: flex;
	flex-direction: column;
}

.header_right>.header_cell {
	flex: 1;
	padding: 0;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.App-body {
	flex: 1;
	padding: 20px;
	display: flex;
	flex-direction: column;
	margin-top: 45px;
	align-items: center;
	text-align: center;
	overflow-y: auto;
	background-color: #516979;
}

.App-body-map {
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	overflow-y: auto;
	background-color: #516979;
}

.App-section {
	margin-bottom: 20px;
	width: 100%;
	max-width: 1024px;
}

.App-section h2 {
	text-align: left;
}

.project-list {
	background-color: #fff;
	color: #000;
	border-radius: 12px;
	padding: 10px;
	max-height: 320px;
	overflow-y: auto;
}

.project-list ul {
	list-style: none;
	padding: 0;
}

.project-list li {
	padding: 10px;
	border-bottom: 1px solid #eee;
	text-align: left;
	cursor: pointer;
}

.project-list li:last-child {
	border-bottom: none;
}

.App-logout-button {
	padding: 10px 30px;
	margin: 20px;
	background-color: #ff5c5c;
	color: white;
	border: none;
	border-radius: 12px;
	cursor: pointer;
	align-self: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.custom-button-icon {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
	margin: 5px;
	z-index: 1000;
}

#mapView {
	width: 100vw;
	height: calc(100vh - 45px);
}

.custom-button-icon:hover {
	opacity: 0.8;
}

.maplibregl-ctrl-group {
	background: none;
	border: none;
}

.testLabel {
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	padding: 5px 10px;
	z-index: 100;
	color: black;
	background-color: white;
	border: none;
}

.button-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	margin-top: 25px;
}

.flexButton {
	font-size: 1em;
	color: white;
	border-radius: 12px;
	padding: 10px 25px;
	z-index: 100;
	background-color: #f93;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease;
}

.flexButton:hover {
	filter: brightness(120%);
	transform: translateY(-2px);
}

@media (max-width: 600px) {
	.button-container {
		flex-direction: column;
		align-items: center;
	}

	.flexButton {
		width: 100%;
		max-width: 300px;
	}
}

.centerButton {
	font-size: 1em;
	color: white;
	border-radius: 12px;
	margin-top: 25px;
	padding: 10px 25px;
	z-index: 100;
	background-color: #f93;
	border: none;
	cursor: pointer;
}

.centerButton:hover {
	filter: brightness(120%);
	transform: translateY(-2px);
}

.columnBox {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-bottom: 20px;
	justify-content: center;
	align-items: center;
	width: 80%;
	text-align: center;
}

.centerMessage {
	margin-top: 15px;
	font-size: 1.2em;
	color: white;
	z-index: 100;
	max-width: 500px;
}

#centerMark {
	z-index: 11;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	opacity: 0.8;
	pointer-events: none;
	user-select: none;
}

.toolButton {
	background-color: white;
	width: 31px;
	height: 31px;
	border-radius: 6px;
	border: none;
	display: inline-block;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
	object-fit: cover;
	z-index: 100;
	cursor: pointer;
	transform: translateY(-50%);
	user-select: none;
	-webkit-user-drag: none;
}

#logoImage {
	margin-top: 4px;
	z-index: 80;
	user-select: none;
	cursor: pointer;
}

.toolButton_w {
	cursor: pointer;
	width: 31px;
	height: 31px;
	z-index: 100;
	user-select: none;
	-webkit-user-drag: none;
}

#goBackButton {
	position: absolute;
	left: 8px;
	top: 7px;
}

#signinButton,
#signoutButton {
	position: absolute;
	right: 8px;
	top: 7px;
}

#copyProjectButton,
#PrintButton,
#screenShotButton,
#datalistButton,
#chatButton,
#favoriteButton,
#shareButton {
	position: absolute;
	top: calc(100% - 22px);
}

#datalistButton {
	left: calc(50% - 115px);
}

#copyProjectButton {
	left: calc(50% - 75px);
}

#shareButton {
	left: calc(50% - 35px);
}

#PrintButton {
	left: calc(50% + 5px);
	opacity: 0.0;
}

#screenShotButton {
	left: calc(50% + 5px);
}

#chatButton {
	left: calc(50% + 45px);
}

#favoriteButton {
	left: calc(50% + 85px);
}

#lineButton,
#measurementButton,
#circleButton,
#polygonButton {
	position: absolute;
	top: 29px;
	transform: translate(-50%, -50%);
}

#lineButton {
	left: calc(50% - 45px);
}

#circleButton {
	left: calc(50%);
}

#polygonButton {
	left: calc(50% + 45px);
}

#measurementButton {
	left: calc(50% + 90px);
}

@media (max-width: 480px) {

	#PrintButton,
	#screenShotButton,
	#copyProjectButton,
	#datalistButton,
	#chatButton,
	#favoriteButton,
	#shareButton {
		top: calc(100% - 52px);
	}
}

#poszeroButton {
	position: absolute;
	top: 200px;
}

#addMarketButton {
	top: 240px;
}

#uploadButton {
	top: 280px;
}

#layerButton,
#settingButton,
#searchButton {
	position: absolute;
	left: 10px;
}

#searchButton {
	top: 29px;
	opacity: 1.0;
}

#settingButton {
	top: 69px;
}

#layerButton {
	top: 109px;
}


#penButton {
	top: calc(50% - 80px);
}

#shapeButton {
	top: calc(50% - 40px);
}

#eraserButton {
	top: 50%;
}

#paletteButton {
	top: calc(50% + 40px);
}

#undoButton {
	top: calc(50% + 100px);
}


#labelScaleDistance {
	z-index: 100;
	position: absolute;
	padding: 3px 10px;
	top: 74%;
	left: 50%;
	background-color: #fff;
	border-radius: 2px;
	color: #000;
	font-size: 0.8em;
	transform: translate(-50%, -50%);
	opacity: 0.8;
	pointer-events: none;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.maplibregl-popup {
	min-width: 140px;
	max-width: 250px;
	background: white;
	margin-top: -9px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	z-index: 200;
}

.maplibregl-popup-content {
	color: black;
	border-radius: 10px !important;
}

.maplibregl-popup-content h3,
.maplibregl-popup-content h4,
.maplibregl-popup-content p {
	margin: 0 0 3px 0;
}

.maplibregl-popup-tip {
	margin-bottom: -9px;
	border-color: white;
}

.file-upload-wrapper {
	position: relative;
	display: inline-block;
}

#fileInput {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 1;
	cursor: pointer;
}

.roundButton_TC {
	position: absolute;
	top: 14px;
	left: 50%;
	transform: translateX(-50%);
	color: white;
	border-radius: 17px;
	padding: 5px 10px;
	z-index: 100;
	background-color: gray;
	border: none;
	cursor: pointer;
	user-select: none;
}

.number_box {
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 22px;
	border-radius: 6px;
	border: 1px solid black;
	position: absolute;
	top: 15px;
	left: calc(50% - 100px);
	transform: translateX(-50%);
	z-index: 100;
	user-select: none;
}

.buttonBlue_s {
	background-color: #0066cc;
}

.buttonRed_s {
	background-color: #cc3300;
}

.buttonBlue_s,
.buttonRed_s {
	margin: 5px 5px 5px 0;
	border: none;
	cursor: pointer;
	font-size: 1em;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	text-decoration: none;
}

.blinking-marker {
	animation: blink 0.5s infinite;
}

@keyframes blink {
	0% {
		opacity: 0.5;
		filter: brightness(0.8);
	}

	50% {
		opacity: 1.0;
		filter: brightness(1.5);
	}

	100% {
		opacity: 0.3;
		filter: brightness(0.5);
	}
}

.custom-scale-control,
.no-mouse-action {
	user-select: none;
	pointer-events: none;
}

.project-info {
	margin-bottom: 5px;
}

.projectList_title {
	font-weight: bold;
	font-size: 1.2em;
}

.project-memo {
	color: #666;
	text-align: left;
}

.project-details {
	display: flex;
	justify-content: space-between;
	font-size: 0.9em;
	color: #888;
}

.project-tags {
	font-size: 12px;
	color: #666;
}

.project-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 20px;
}

.project-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 10px;
	padding: 15px;
	cursor: pointer;
	transition: box-shadow 0.3s ease;
}

.project-card:hover {
	filter: brightness(105%);
	transform: translateY(-1px);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-thumbnail {
	width: 100%;
	height: 150px;
	overflow: hidden;
	border-radius: 8px;
	margin-bottom: 10px;
}

.project-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.project-title {
	margin: 0 0 10px 0;
	font-weight: bold;
	white-space: nowrap;
	font-size: 18px;
	color: #888;
}

.project-permission {
	margin: 0 0 10px 0;
	font-weight: bold;
	white-space: nowrap;
	font-size: 15px;
	color: #888;
}

.project-owner,
.project-memo,
.project-time,
.project-tags {
	color: #888;
	margin: 5px 0;
	font-size: 14px;
}

.print-map-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.print-map-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.print-map-content h2 {
	margin-top: 0;
}

.print-map-content select,
.print-map-content input,
.print-map-content button {
	margin: 5px;
	padding: 5px;
}

@media (max-width: 768px) {
	.project-grid {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
}

@media (max-width: 480px) {
	.project-grid {
		grid-template-columns: 1fr;
	}

	.load-more-button {
		width: 80%;
		font-size: 14px;
		padding: 8px 16px;
	}

	#PrintButton,
	#screenShotButton,
	#copyProjectButton,
	#datalistButton,
	#chatButton,
	#favoriteButton,
	#shareButton {
		top: calc(100% - 52px);
	}
}

.load-more-button {
	background-color: #39c;
	color: white;
	border: none;
	border-radius: 20px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s, transform 0.2s;
	margin: 20px auto;
	display: block;
	width: 200px;
	text-align: center;
}

.load-more-button:hover {
	filter: brightness(120%);
	transform: translateY(-2px);
}

.load-more-button:active {
	transform: translateY(0);
}

.load-more-button:focus {
	outline: none;
	box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.3);
}

.search-container {
	position: absolute;
	top: 4px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1000;
	width: 35%;
	max-width: 400px;
	padding: 0 10px;
	box-sizing: border-box;
}

.search-form {
	display: flex;
	align-items: center;
	width: 100%;
}

.search-input {
	flex-grow: 1;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 8px 0 0 8px;
	font-size: 16px;
}

.search-button {
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	border-left: none;
	border-radius: 0 8px 8px 0;
	cursor: pointer;
	padding: 6.5px 12px;
	font-size: 16px;
	height: 36px;
}

.search-icon-button {
	position: absolute;
	top: -2.5px;
	left: 75%;
	transform: translateX(-50%);
}


.search-icon-button,
.close-button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 8px;
	font-size: 20px;
	color: white;
}

.search-container.expanded {
	position: absolute;
	top: 4px;
	left: 45%;
	transform: translateX(-50%);
	justify-content: center;
	max-width: none;
}

.search-container.expanded .search-form {
	position: relative;
	width: 100%;
	max-width: 600px;
}

.search-container.expanded .close-button {
	position: absolute;
	width:45px;
	left:-47px;
	font-size: 16px;
	border-radius: 8px;
	top: -2px;
	background-color: #f00;
}

.search-results-header {
	display: flex;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 15px;
}

.search-results-header h2 {
	margin: 0;
	font-size: 25px;
}

.search-results-header button {
	background-color: #f93;
	height: 30px;
	margin: -5px 10px 0 5px;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
	font-size: 0.9em;
}

.search-results-header button:hover {
	filter: brightness(120%);
	transform: translateY(-2px);
}

@media (max-width: 320px) {
	.search-container:not(.expanded) {
		width: auto;
		transform: none;
		left: auto;
		right: 10px;
	}
}

.rounded-image {
	z-index: 100;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

.rounded-image-s {
	z-index: 100;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
}

.license {
	color: white;
	font-size: 0.8em;
	margin: 10px;
	text-align: center;
}

.license a{
	color: white;
}

#elevationLabel {
	height:20px;
	font-size: 12px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 150%);
	background-color: rgba(255, 255, 255, 0.5);
	padding: 5px 10px;
	border-radius: 12px;
	z-index: 10;
	-webkit-user-drag: none;
	user-select: none;
}

.project-tag {
	cursor: pointer;
	color: #0066cc;
	/* Blue color */
	margin-right: 5px;
	transition: font-weight 0.3s ease;
}

.project-tag:hover {
	font-weight: bold;
}

.project-card {
	position: relative;
}

.favorite-button {
	position: absolute;
	bottom: 10px;
	right: 10px;
	width: 24px;
	/* アイコンの大きさに合わせて調整 */
	height: 24px;
	/* アイコンの大きさに合わせて調整 */
	cursor: pointer;
	background: none;
	border: none;
	padding: 0;
}
